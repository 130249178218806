///
/// Ethereal by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Contact Icons */

	ul.contact-icons {
		list-style: none;
		padding-left: 0;

		> li {
			margin: 1.25rem 0 0 0;
			padding-left: 0;

			&:before {
				display: inline-block;
				width: 2.25rem;
				height: 2.25rem;
				line-height: 2.25rem;
				border-radius: 2.25rem;
				background-color: rgba(_palette(fg-bold), 1);
				color: _palette(bg);
				cursor: default;
				font-size: 1.125rem;
				margin-right: 1rem;
				text-align: center;
				vertical-align: middle;

				body.is-ie & {
					line-height: 2.125;
				}
			}

			a {
				border-bottom: 0;
			}
		}

		&.color1 {
			> li {
				&:before {
					color: _palette(accent1);
				}
			}
		}

		&.color2 {
			> li {
				&:before {
					color: _palette(accent2);
				}
			}
		}

		&.color3 {
			> li {
				&:before {
					color: _palette(accent3);
				}
			}
		}

		&.color4 {
			> li {
				&:before {
					color: _palette(accent4);
				}
			}
		}
	}