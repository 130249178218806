///
/// Ethereal by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper */

#wrapper {
  @include vendor('display', 'flex');
  @include vendor('flex-direction', 'row');
  //@include vendor('transition', (
  //        'opacity 1s ease-out',
  //        'transform 0.75s ease-out'
  //));
  @include vendor('transition-delay', '0.25s');
  cursor: default;
  position: relative;
  height: 40rem;
  box-shadow: 0 2rem 4rem 0.25rem transparentize(_palette(bg), 0.425);

  > .scrollZone {
    position: fixed;
    width: 6rem;
    height: inherit;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: -ms-grab;
    cursor: grab;
    z-index: _misc(z-index-base) + 100;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  > .copyright {
    position: absolute;
    bottom: -3rem;
    left: 2rem;
    font-size: 0.8rem;
    color: transparentize(_palette(bg), 0.625);
    margin-bottom: 0;

    a {
      &:hover {
        color: inherit;
      }
    }
  }

  &.is-dragging {
    @include vendor('user-select', 'none');
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: -ms-grab;
    cursor: grab;

    * {
      @include vendor('user-select', 'none');
    }

    *:not(a, .image) {
      cursor: -moz-grab;
      cursor: -webkit-grab;
      cursor: -ms-grab;
      cursor: grab;
    }
  }

  &.is-dragged {
    * {
      @include vendor('pointer-events', 'none');
    }
  }
}

@include breakpoint('<=small') {
  #wrapper {
    @include vendor('flex-direction', 'column');
    height: auto;
    margin: 0 0 5rem 0;
    box-shadow: 0 0.25rem 1.5rem 0.25rem transparentize(_palette(bg), 0.5);

    > .scrollZone {
      display: none;
    }

    > .copyright {
      display: block;
      width: 100%;
      text-align: center;
    }

    body.is-preload & {
      @include vendor('transform', 'translateY(1rem)');
    }
  }
}

@include breakpoint('<=xsmall') {
  #wrapper {
    box-shadow: none;

    body.is-preload & {
      @include vendor('transform', 'none');
    }
  }
}

html.full-height {
  height: initial;
}
html.full-height > body {
  overflow: auto;

  & #wrapper {
    height: initial;

    & section {
      flex-shrink: initial;
      & .content {
        width: 100%;
      }
    }
  }

}

@include breakpoint('<=small') {
  ul.actions {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ul.actions li {
    padding: 0 0 .75rem .75rem;
  }
}
