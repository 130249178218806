///
/// Ethereal by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Grid Icons */

	ul.grid-icons {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		@include vendor('justify-content', 'center');
		list-style: none;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 0;

		.icon {
			display: block;
			position: relative;
			width: 100%;
			text-align: center;

			&:before {
				display: block;
				width: 6rem;
				height: 6rem;
				line-height: 6rem;
				border-radius: 6rem;
				box-shadow: inset 0 0 0 2px _palette(border);
				font-size: 2.5rem;
				margin: 0 auto;
				text-align: center;

				body.is-ie & {
					line-height: 2.375;
				}
			}
		}

		> li {
			@include vendor('flex-grow', '0');
			@include vendor('flex-shrink', '0');
			position: relative;
			margin: 1.5rem 0 0 1.5rem;
			padding-left: 0;
		}

		&.connected {
			> li {
				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 1.5rem;
					height: 2px;
					top: 50%;
					left: -1.5rem;
					background-color: _palette(border);
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 2px;
					height: 1.5rem;
					top: -1.5rem;
					left: 50%;
					background-color: _palette(border);
				}
			}
		}

		&.two {
			width: 14rem;

			> li {
				&:nth-child(-n + 2) {
					margin-top: 0;

					&:after {
						display: none;
					}
				}

				&:nth-child(2n - 1) {
					margin-left: 0;

					&:before {
						display: none;
					}
				}
			}
		}

		&.three {
			width: 21.5rem;

			> li {
				&:nth-child(-n + 3) {
					margin-top: 0;

					&:after {
						display: none;
					}
				}

				&:nth-child(3n - 2) {
					margin-left: 0;

					&:before {
						display: none;
					}
				}
			}
		}

		&.four {
			width: 29rem;

			> li {
				&:nth-child(-n + 4) {
					margin-top: 0;

					&:after {
						display: none;
					}
				}

				&:nth-child(4n - 3) {
					margin-left: 0;

					&:before {
						display: none;
					}
				}
			}
		}

		@include breakpoint('<=small') {
			@include vendor('justify-content', 'flex-start');
			width: 100% !important;
			margin: -1rem 0 _size(element-margin) -1rem;

			.icon {
				&:before {
					width: 4.5rem;
					height: 4.5rem;
					line-height: 4.5rem;
					font-size: 1.75rem;
				}
			}

			> li {
				margin: 1rem 0 0 1rem !important;

				&:before {
					display: none !important;
				}

				&:after {
					display: none !important;
				}
			}
		}
	}